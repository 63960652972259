import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import Loader from './components/Loader/Loader';
import NoUserMessage from './components/NoUserMessage/NoUserMessage';
import Tokens from './components/Tokens/Tokens';
import ConnectButton from './components/ConnectButton/ConnectButton';
import Levels from './components/Levels/Levels';
import CopyButton from './components/CopyButton/CopyButton';
import AddressHandler from './components/AddressHandler/AddressHandler';

const App = () => {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const isUserSent = useRef(false);
  const initialized = useRef(false);
  const userApiUrl = process.env.REACT_APP_USER_API_URL;
  const tonConnectManifestUrl = process.env.REACT_APP_TONCONNECT_MANIFEST_URL;
  const telegramReturnUrl = process.env.REACT_APP_TELEGRAM_RETURN_URL;

  useEffect(() => {
    const MINIMUM_LOADING_TIME = 3000;
    const startTime = Date.now();

    if (!initialized.current) {
      initialized.current = true;
      console.error('Hey! What are you looking at here? Better go hang out at our party!');

      if (window.Telegram.WebApp) {
        window.Telegram.WebApp.ready();
        window.Telegram.WebApp.expand();
        window.Telegram.WebApp.setHeaderColor('#000000');

        const urlParams = new URLSearchParams(window.location.search);
        const friendIdFromUrl = urlParams.get('tgWebAppStartParam');

        const user = window.Telegram.WebApp.initDataUnsafe?.user;
        if (user) {
          setUser(user);

          if (!isUserSent.current) {
            isUserSent.current = true;

            const dataToSend = {
              telegramId: user.id,
              friendId: friendIdFromUrl || null,
            };

            fetch(userApiUrl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(dataToSend),
            })
              .then(response => response.json())
              .then(data => {
                setUserData(data);
              })
              .catch(error => {
                console.error('Error adding or fetching user:', error);
              })
              .finally(() => {
                const elapsedTime = Date.now() - startTime;
                const remainingTime = MINIMUM_LOADING_TIME - elapsedTime;
                if (remainingTime > 0) {
                  setTimeout(() => setIsLoading(false), remainingTime);
                } else {
                  setIsLoading(false);
                }
              });
          } else {
            setTimeout(() => {setIsLoading(false);}, 3000);
          }
        } else {
          setTimeout(() => {setIsLoading(false);}, 3000);
        }
      } else {
        console.error('Telegram WebApp API is not available');
        setTimeout(() => {setIsLoading(false);}, 3000);
      }
    }
  }, [userApiUrl]);

  if (isLoading) {
    return <Loader />;
  }

  if (!user) {
    return (
      <div className="App">
        <NoUserMessage />
      </div>
    );
  }

  return (
    <TonConnectUIProvider
      manifestUrl={tonConnectManifestUrl}
      actionsConfiguration={{
        twaReturnUrl: telegramReturnUrl
      }}
    >
      <div className="App">
          {userData && <Tokens userData={userData} />}
          <ConnectButton />
          <Levels userData={userData} />
          {user && <CopyButton telegramId={user.id} />}
          {user && userData && <AddressHandler userId={user.id} walletAddress={userData.wallet_address} />}
      </div>
    </TonConnectUIProvider>
  );
};

export default App;