import React, { useState } from 'react';
import './Levels.css';

const Levels = ({ userData }) => {
  const [showLevelsMenu, setShowLevelsMenu] = useState(false);
  const [isButtonPressed, setIsButtonPressed] = useState(false);

  const toggleLevelsMenu = (e) => {
    if (e.target.closest('.custom-level-button')) {
      setIsButtonPressed(true);
      setTimeout(() => {
        setIsButtonPressed(false);
      }, 100);
    }

    setShowLevelsMenu(!showLevelsMenu);

    const tokensContainer = document.querySelector('.tokens-container');

    if (tokensContainer) {
      if (showLevelsMenu) {
        tokensContainer.style.paddingTop = 'calc((100vh - 278px) / 2)';
        tokensContainer.style.paddingBottom = 'calc((100vh - 278px) / 2)';
      } else {
        tokensContainer.style.paddingTop = 'calc((100vh - 486px) / 2)';
        tokensContainer.style.paddingBottom = 'calc((100vh - 70px) / 2)';
      }
    }
  };

  return (
    <div className="levels-wrapper">
      <div className='levels-container'>
        <button 
          className={`custom-level-button ${isButtonPressed ? 'pressed' : ''}`}
          onClick={toggleLevelsMenu}
        >
          <span>&#128064;</span>
          <span className="button-text">See your achievements</span>
          <span>&#128064;</span>
        </button>
      </div>
      
      <div 
        className={`levels-menu-overlay ${showLevelsMenu ? 'show' : ''}`} 
        onClick={toggleLevelsMenu}
      ></div>

      <div className={`levels-menu ${showLevelsMenu ? 'show' : ''}`}>
        <div className="level-container">
          <div>&#128526;  Tier 1 friends</div>
          <div>{userData ? `${new Intl.NumberFormat('en-US').format(userData['1lvl'])} invited \u{1F60E}` : '0 invited  \u{1F60E}'}</div>
        </div>
        <div className="level-container">
          <div>&#128521;  Tier 2 friends</div>
          <div>{userData ? `${new Intl.NumberFormat('en-US').format(userData['2lvl'])} invited \u{1F609}` : '0 invited  \u{1F609}'}</div>
        </div>
        <div className="level-container">
          <div>&#128539;  Tier 3 friends</div>
          <div>{userData ? `${new Intl.NumberFormat('en-US').format(userData['3lvl'])} invited \u{1F61B}` : '0 invited  \u{1F61B}'}</div>
        </div>
        <div className="level-container">
          <div>&#128527;  Tier 4 friends</div>
          <div>{userData ? `${new Intl.NumberFormat('en-US').format(userData['4lvl'])} invited \u{1F60F}` : '0 invited  \u{1F60F}'}</div>
        </div>
        <div className="level-container">
          <div>&#128525;  Tier 5 friends</div>
          <div>{userData ? `${new Intl.NumberFormat('en-US').format(userData['5lvl'])} invited \u{1F60D}` : '0 invited  \u{1F60D}'}</div>
        </div>

        <button onClick={toggleLevelsMenu} className="levels-close-button">
          Close
        </button>
      </div>
    </div>
  );
};

export default Levels;