import React from 'react';
import './Tokens.css';

const Tokens = ({ userData }) => {
  return (
    <div className="tokens-container">
      <div className="tokens">
        {userData ? new Intl.NumberFormat('en-US').format(userData.tokens) : '0'}
      </div>
    </div>
  );
};

export default Tokens;