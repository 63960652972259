import { useEffect, useState } from 'react';
import { useTonAddress } from '@tonconnect/ui-react';

const AddressHandler = ({ userId, walletAddress }) => {
  const userFriendlyAddress = useTonAddress();
  const [prevAddress, setPrevAddress] = useState(walletAddress || '');
  const walletApiUrl = process.env.REACT_APP_WALLET_API_URL;

  useEffect(() => {
    if (userId && userFriendlyAddress && userFriendlyAddress !== prevAddress) {
      setPrevAddress(userFriendlyAddress);

      fetch(walletApiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          telegramId: userId,
          walletAddress: userFriendlyAddress,
        }),
      })}
  }, [userFriendlyAddress, userId, prevAddress, walletApiUrl]);

  return null;
};

export default AddressHandler;