import React, { useState, useRef } from 'react';
import './CopyButton.css';

const CopyButton = ({ telegramId }) => {
    const [copied, setCopied] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const timeoutRef = useRef(null);
    const telegramBaseUrl = process.env.REACT_APP_TELEGRAM_BASE_URL;

    const handleCopy = () => {
        const friendlyLink = `${telegramBaseUrl}${telegramId}`;
        const textArea = document.createElement("textarea");
        textArea.value = friendlyLink;
        document.body.appendChild(textArea);
        textArea.select();
        try {
            document.execCommand('copy');
            setCopied(true);
            setShowMessage(true);

            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }

            timeoutRef.current = setTimeout(() => {
                setCopied(false);
                setShowMessage(false);
            }, 3000);

            setTimeout(() => {
                setCopied(false);
            }, 100);
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }
        document.body.removeChild(textArea);
    };

    return (
        <div className="copy-container">
            <button 
                onClick={handleCopy} 
                className={`custom-copy-button ${copied ? 'copied' : ''}`}
            >
                <span>&#127881;</span>
                <span className="button-text">Invite friends to our party!</span>
                <span>&#127881;</span>
            </button>

            <div className={`toast-message ${showMessage ? 'show' : ''}`}>
                <span>&#x2705;</span>
                <span className="button-text">Invite link copied successfully!</span>
                <span>&#x2705;</span>
            </div>
        </div>
    );
};

export default CopyButton;