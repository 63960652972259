import React from 'react';
import './NoUserMessage.css';

const NoUserMessage = () => {
  return (
    <div className="no-user-container">
      <div className="no-user-text">PARTY</div>
      <button 
        onClick={() => window.open("https://t.me/partyontonbot", "_self")}
        className="no-user-button"
        >
        <span>&#128293;</span>
        <span className="button-text">Join our party!</span>
        <span>&#128293;</span>
    </button>
    </div>
  );
};

export default NoUserMessage;