import React, { useEffect, useState } from 'react';
import './ConnectButton.css';
import { useTonConnectUI, useTonAddress } from '@tonconnect/ui-react';

const ConnectButton = () => {
    const [tonConnectUI] = useTonConnectUI();
    const userFriendlyAddress = useTonAddress(); 
    const [isConnected, setIsConnected] = useState(false);
    const [showManageMenu, setShowManageMenu] = useState(false);
    const [isButtonPressed, setIsButtonPressed] = useState(false);

    useEffect(() => {
        const logWalletAddress = () => {
            const walletStatus = tonConnectUI.connected;
            setIsConnected(walletStatus);
        };

        const unsubscribe = tonConnectUI.onStatusChange(logWalletAddress);
        return () => {
            unsubscribe();
        };
    }, [tonConnectUI]);

    const handleConnect = async () => {
        setIsButtonPressed(true);
        setTimeout(() => {
            setIsButtonPressed(false);
        }, 100);
    
        try {
            await tonConnectUI.openModal();
        } catch (error) {
            console.error('Error opening modal:', error);
        }
    };

    const handleDisconnect = async () => {
        try {
            await tonConnectUI.disconnect();
            setIsConnected(false);
            setShowManageMenu(false); 
        } catch (error) {
            console.error('Error disconnecting wallet:', error);
        }
    };

    const toggleManageMenu = (e) => {
        if (e.target.closest('.custom-connect-button')) {
            setIsButtonPressed(true);
            setTimeout(() => {
                setIsButtonPressed(false);
            }, 100);
        }
            setShowManageMenu(!showManageMenu);
    };

    return (
        <div>
            <div className="header-container">
                {!isConnected ? (
                    <button 
                        onClick={handleConnect} 
                        className={`custom-connect-button ${isButtonPressed ? 'pressed' : ''}`}
                    >
                        <span>&#129297;</span>
                        <span className="button-text">Connect your wallet</span>
                        <span>&#129297;</span>
                    </button>
                ) : (
                    <button 
                        onClick={toggleManageMenu} 
                        className={`custom-connect-button ${isButtonPressed ? 'pressed' : ''}`}
                    >
                        <span>&#129297;</span>
                        <span className="button-text">Manage your wallet</span>
                        <span>&#129297;</span>
                    </button>
                )}
            </div>
            <div 
                className={`manage-menu-overlay ${showManageMenu ? 'show' : ''}`} 
                onClick={toggleManageMenu}
            ></div>
            <div className={`manage-menu ${showManageMenu ? 'show' : ''}`}>
                <button className="menu-address" disabled>
                    <span>&#128142;</span>
                    <span className="button-text">
                        {userFriendlyAddress ? 'Address: ' + userFriendlyAddress.slice(0, 4) + '...' + userFriendlyAddress.slice(-4) : 'Loading...'}
                    </span>
                    <span>&#128142;</span>
                </button>
                <button onClick={handleDisconnect} className="menu-disconnect-button">
                    <span>&#9940;</span>
                    <span className="button-text">Disconnect your wallet</span>
                    <span>&#9940;</span>
                </button>
                <button onClick={toggleManageMenu} className="menu-close-button">
                    Close
                </button>
            </div>
        </div>
    );
};

export default ConnectButton;